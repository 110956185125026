#map {
	height: 500px;
}

.button {
	display: inline-block;
	padding: 10px 20px;
	background-color: coral;
	color: white;
	line-height: 20px;
	border-radius: 18px;
	margin: 5px;
}

.race-tile-title {
	overflow: scroll;
	max-width: 250px;
	white-space: nowrap;
}

.race-tile-props {
	line-height: 50px;
	padding: 10px 0;
}

.race-tile-prop {
	display: inline-block;
	margin: 10px;
}

.hidden {
	display: none;
}

.races-tabs-container {
	margin-right: 15px;
}

.races-tabs-container .tab-content {
	max-height: 380px;
	overflow: scroll;
}

.race-tab {
	overflow: scroll;
}

.race-submit-button {
	width: 100%;
	height: 40px;
	line-height: 40px;
	padding: 5px 0;
	text-align: center;
}

.race-tile-select {
	display: inline-block;
	vertical-align: top;
	height: 100px;
	width: 42px;
}
.race-tile-details {
	display: inline-block;
	padding: 10px;
}
.race-tile-buttons {
	padding: 10px 0;
}

.race-tile-button {
	margin-right: 10px;
}

.race-tile-container {
	border-radius: 3px;
}

.race-tile-container-selected {
}

.action-row {
	padding: 10px 0;
}

.action-item {
	display: block !important;
}

.score-box {
	font-size: 30px;
}

.race-line {
	padding: 5px 10px;
	overflow-x: scroll;
	white-space: nowrap;
	width: 100%;
	line-height: 30px;
}
