html {
  background-color: #121C31;
}

.root-div {
  background-color: #121C31;
  /* color: #c3a343; */
  /* color: #a8943d; */
  color: #fff;
  height: 100vh;
}

.centered {
  text-align: center;
}

.menu {
  height: 10%;
  padding: 10px;
  display: flex;
  align-items: flex-start;
}

.menu-button {
  color: #fff;
  margin: 0 5px;
}

.menu-button-selected {
  border-bottom: 5px solid #007bff;
}

.menu-button-text {
  display: inline-block;
  padding: 5px 10px;
}

.main-logo-container {
  height: 80%;
  margin-top: 5%;
}

.back-white {
	background-color: #f7f7f7;
}

.rounded {
  border-radius: 5px;
}

.loginout-wrapper {
  margin-left: auto;
}

.gold-text {
  color: #c3a343;
}

.prof-user {
  border-radius: 5px;
  padding: 5px;
}

.prof-tile {
  padding: 10px;
  text-align: center;
  display: inline-block;
  width: 100px;
  overflow: scroll;
}
.prof-tile-title {
  font-size: medium;
}

.prof-tile-value {
  font-size: large;
  font-weight: bold;
}

.prof-img {
  vertical-align: top;
}

.prof-leaderboard {
  margin: 5px 0 0;
}

.prof-form {
  border: 4px solid #121C31;
  margin: 5px 0 0;
}

.prof-form-name {
  margin: 5px;
}

.prof-form-file {
  margin: 5px;
  border: 1px solid #121C31;
}

.prof-form-button {
  margin: 5px;
}

.coming-soon-container {
  position: relative;
  margin: 5px 0 0;
}

.coming-soon {
  position: absolute;
  background-color: rgb(18, 28, 49, .7);
  height: 100%;
  width: 100%;
  text-align: center;
  z-index: 2;
}

.coming-soon-text {
  margin: auto;
  width: 50%;
  color: #c3a343;
  font-size: large;
  font-weight: bold;
}

.coming-soon-children {
  position: relative;
  opacity: .7;
}

.item {
  display: inline-block;
  border: 5px solid #c3a343;
  margin: 15px;
}

.item-title {
  height: 20px;
  overflow: scroll;
}
